import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faList from '@fortawesome/fontawesome-free-solid/faList';
import { translate } from 'react-i18next';

import './style.css';
@translate(['filter'], { wait: true })
export default class NavBtn extends Component {
  render() {
    const { numberOfPlaces, forDisabled, t } = this.props;
    return (
      <div className="nav-btn" onClick={this.props.onClick}>
        <div className="nav-btn__inner inline-flex flex-center w-100">
          <div className="nav-btn-number"> {numberOfPlaces}</div>
          <div className="nav-btn-title">{t('filter:placesAll')}</div>
          <div className="nav-btn-number"> {forDisabled}</div>
          <div className="nav-btn-title">{t('filter:categories:forDisabled')}</div>
          <div className="nav-btn-icon">
            <FontAwesomeIcon icon={faList} />
          </div>
        </div>
      </div>
    );
  }
}

NavBtn.propTypes = {
  numberOfPlaces: PropTypes.number,
  onClick: PropTypes.func
};
