import React from 'react';

import { Button } from 'reactstrap';
import AbonementCost from './AbonementCost';

import './style.css';

function AbonementCard(props) {
  const { title, parkingPermit, parkingPermitCode, zones, cost, onClick, parkingPermitType, licensePlate, startDate } = props;

  function handleClick() {
    onClick({ parkingPermitCode, parkingPermit, cost, parkingPermitType, licensePlate, startDate });
  }

  return (
    <div className="abonement">
      <div className="abonement__title">{title}</div>
      <AbonementCost cost={cost} />
      <div className="abonement__descr">
        <div className="abonement__zones">Зоны действия</div>
        <div className="abonement__zone">{zones}</div>
      </div>
      <Button className="abonement__pay" color="primary" onClick={handleClick}>
        Оплатить
      </Button>
    </div>
  );
}

AbonementCard.defaultProps = {
  title: '',
  parkingPermit: '',
  parkingPermitCode: '',
  parkingPermitType: '',
  zone: 0,
  cost: 0,
  licensePlate: '',
  startDate: 0
};

export default AbonementCard;
